<template>
  <v-dialog v-model="showDialog" max-width="400px">
    <v-card>
      <v-card-title class="primarydark--text">
        Send an offer to {{ currentOfferToName }}
      </v-card-title>
      <v-card-text>
        <p class="text-body-2 font-italic">... then they can reply directly to you at {{$store.state.user.phoneNumber}}.</p>
        <v-text-field v-model.number="currentOfferAdd.fee" type="number"
          :rules="[ inputRules.offerFeeCheck ]"
          prefix="£" prepend-icon="mdi-cash-multiple" label="Fee" hide-spin-buttons
        ></v-text-field>
        <v-textarea v-model.trim="currentOfferAdd.skills" rows="2"
          :rules="[ inputRules.offerSkillsCheck ]"
          prepend-icon="mdi-music-clef-treble" label="Skills required from this performer"
        ></v-textarea>
        <v-checkbox v-model="currentOfferAdd.multicall"
          prepend-icon="mdi-email-multiple-outline" label="Multiple calls out"
        ></v-checkbox>
        <v-textarea v-model.trim="currentOfferAdd.note" rows="2"
          prepend-icon="mdi-text" label="Note for this individual performer (optional)"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primarydark" @click="$store.commit('gigMakeOfferTo', null)">
          Cancel
        </v-btn>
        <v-btn text color="primarydark" @click="makeOffer" :disabled="!offerValid">
          Send
        </v-btn>
      </v-card-actions>
      <v-progress-linear
        :active="progressMakingOffer"
        :indeterminate="progressMakingOffer"
        color="secondary" height="6"
      ></v-progress-linear>
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from '@/main'
import { mapGetters } from 'vuex'
import { fbfs, fbfuncs } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'
import { doc, getDoc } from 'firebase/firestore'

export default {
  props: {
  },
  data() {
    return {
      currentOffer: null,
      currentOfferAdd: {
        fee: '',
        skills: '',
        multicall: false,
        note: ''
      },
      inputRules: {
        offerFeeCheck: () => this.offerFeeValid || 'Number between £10 and £9999',
        offerSkillsCheck: () => this.offerSkillsValid || 'Text from 3 to 200 letters'
      },
      progressMakingOffer: false
    }
  },
  computed: {
    currentOfferToName() {
      return this.currentOffer ? this.currentOffer.to.name : ''
    },
    offerFeeValid() {
      const n = Number(this.currentOfferAdd.fee)
      return n >= 10 && n <= 9999
    },
    offerSkillsValid() {
      const n = this.currentOfferAdd.skills.length
      return n >= 3 && n <= 200
    },
    offerValid() {
      return this.offerFeeValid && this.offerSkillsValid
    },
    showDialog() {
      return !!this.gigMakeOfferTo
    },
    ...mapGetters([
      'gigId',
      'gigProfiles',
      'gigMakeOfferTo'
    ])
  },
  methods: {
    async makeOffer() {
      if (this.currentOffer) {
        this.progressMakingOffer = true
        try {
          Object.assign(this.currentOffer, this.currentOfferAdd, { fev: this.$store.getters.frontendVersion })
          const makeOffer = httpsCallable(fbfuncs, 'makeOffer')
          await makeOffer(this.currentOffer)
          eventBus.$emit('appWarningSet', { color: 'success', message: 'Offer sent' })
        } catch (e) {
          eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
        }
        this.progressMakingOffer = false
      }
      this.currentOffer = null
      this.$store.commit('gigMakeOfferTo', null)
    }
  },
  async created() {
    let profileId = this.gigMakeOfferTo
    let offer = {
      from: {
        id: this.$store.state.user.uid,
        phone: this.$store.state.user.phoneNumber,
        name: this.$store.getters.profileName
      },
      to: {
        id: profileId,
        name: this.gigProfiles[profileId].name
      },
      gig: {
        id: this.gigId
      }
    }
    let offerAdd = { fee: '', skills: '', multicall: false, note: '' }

    try {
      const offerId = this.gigProfiles[profileId].offer.id
      const offerSnap = await getDoc(doc(fbfs, 'offers', offerId))
      if (offerSnap.exists()) {
        const prevOffer = offerSnap.data()
        offerAdd = {
          fee: prevOffer.fee,
          skills: prevOffer.skills,
          multicall: prevOffer.multicall,
          note: prevOffer.note
        }
      }
    } catch (e) {
      if (!(e instanceof TypeError)) {
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
      }
    }
    this.currentOfferAdd = offerAdd
    this.currentOffer = Object.assign(offer, offerAdd)
  }
}
</script>
