const getters = {
  activeGigs: (state) => () => {
    return Object.keys(state.gigList)
      .filter((gk) => state.gigList[gk].archived === false)
      .map((gk) => state.gigList[gk])
      .sort((g1, g2) => {
        if (g1.date < g2.date) {
          return -1
        }
        if (g1.date > g2.date) {
          return 1
        }
        return 0
      })
  },
  archivedGigs: (state) => () => {
    return Object.keys(state.gigList)
      .filter((gk) => state.gigList[gk].archived === true)
      .map((gk) => state.gigList[gk])
      .sort((g1, g2) => {
        if (g1.date < g2.date) {
          return -1
        }
        if (g1.date > g2.date) {
          return 1
        }
        return 0
      })
  },
  gigId(state) {
    return state.gigId
  },
  gigTitle(state) {
    return state.gigTitle
  },
  gigDate(state) {
    return state.gigDate
  },
  gigLocation(state) {
    if (state.gigLocation) {
      return state.gigLocation
    } else {
      return { name:'', address: '', latlng: { lat: null, lng: null }}
    }
  },
  gigDetails(state) {
    return state.gigDetails
  },
  gigNotes(state) {
    return state.gigNotes
  },
  gigProfiles(state) {
    if (state.gigProfiles) {
      return state.gigProfiles
    } else {
      return {}
    }
  },
  gigProfilesWithOffer(state) {
    if (state.gigProfiles) {
      return Object.keys(state.gigProfiles)
      .filter(pk => Object.hasOwn(state.gigProfiles[pk], 'offer'))
      .map(pk => state.gigProfiles[pk])
      .sort((p1, p2) => {
        if (p1.offer.sent > p2.offer.sent) {
          return -1
        }
        if (p1.offer.sent < p2.offer.sent) {
          return 1
        }
        return 0
      })
    } else {
      return {}
    }
  },
  gigArchived(state) {
    return state.gigArchived
  },
  gigProfilesFound(state) {
    return state.gigProfilesFound
  },
  gigProfilesFoundRefreshKey(state) {
    return state.gigProfilesFoundRefreshKey
  },
  gigMakeOfferTo(state) {
    return state.gigMakeOfferTo
  }
}

import { fbfs } from '@/plugins/firebase'
import { doc, onSnapshot } from 'firebase/firestore'

const mutations = {
  updateGigList(state, payload) {
    if (payload.data) {
      let g = {
        id: payload.id,
        title: payload.data.title,
        location: { address: payload.data.location.address },
        date: payload.data.date,
        archived: payload.data.archived
      }
      state.gigList[g.id] = g
    } else {
      delete state.gigList[payload.id]
    }
  },
  resetGig(state) {
    state.gigId = null
    state.gigTitle = null
    state.gigDate = null
    state.gigLocation = null
    state.gigDetails = null
    state.gigNotes = null
    state.gigProfiles = null
    state.gigArchived = false
    state.gigProfilesFound = {}
    state.gigProfilesFoundRefreshKey += 1
    state.gigMakeOfferTo = null
  },
  deleteGigList(state) {
    state.gigList = {}
    console.log('Delete gig list')
  },
  setGigProfilesFound(state, payload) {
    state.gigProfilesFound = payload
    this.commit('updateGigProfilesFound')
  },
  updateGigProfilesFound(state) {
    // Update the selected flag of found profiles
    Object.keys(state.gigProfilesFound).forEach((id) => {
      state.gigProfilesFound[id].id = id
      if (state.gigProfiles && Object.hasOwn(state.gigProfiles, id)) {
        state.gigProfilesFound[id].selected = state.gigProfiles[id].selected
      } else {
        state.gigProfilesFound[id].selected = false
      }
    })
    state.gigProfilesFoundRefreshKey += 1
  },
  gigMakeOfferTo(state, payload) {
    state.gigMakeOfferTo = payload
  }
}

const actions = {
  setGigId({ state, commit, rootState }, payload) {

    state.gigId = payload

    if (state.dbUnsubscribeGigChanges) {
      state.dbUnsubscribeGigChanges()
      state.dbUnsubscribeGigChanges = null
    }

    if (rootState.user && state.gigId) {
      // Subscribe to database changes
      const gRef = doc(fbfs, 'gigs/' + rootState.user.uid + '/gigsof', state.gigId)
      state.dbUnsubscribeGigChanges = onSnapshot(gRef, (doc) => {
        const g = doc.data()
        if (g) {
          state.gigTitle = g.title
          state.gigDate = g.date
          state.gigLocation = g.location
          state.gigDetails = g.details
          state.gigNotes = g.notes
          state.gigProfiles = g.profiles
          if (state.gigProfiles) {
            Object.keys(state.gigProfiles).forEach((id) => {
              state.gigProfiles[id].id = id
            })
          }
          state.gigArchived = g.archived
          commit('updateGigProfilesFound')
        }
      })
    } else {
      commit('resetGig')
    }
  },
}

const state = {
  gigList: {},
  gigId: null,
  gigTitle: null,
  gigDate: null,
  gigLocation: null,
  gigDetails: null,
  gigNotes: null,
  gigProfiles: null,
  gigArchived: false,
  gigProfilesFound: {},
  gigProfilesFoundRefreshKey: 1,
  gigMakeOfferTo: null,
  dbUnsubscribeGigChanges: null
}

export default {
  state,
  getters,
  mutations,
  actions
}
