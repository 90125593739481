<template>
  <div>
    <tab-title>Areas</tab-title>
    <tab-content>
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <action-card class="mb-4">Type your chosen location, click the search button and choose from list. Select up to 10 locations where you would like to work. Adjust the size of the area around each location by clicking the pin button and moving the slider.</action-card>
          <action-card v-if="showSubscribeWarning" type="warning" to-route="setsubscriptionconfig" large class="mb-4">
            You need to subscribe to list your skills and areas in the directory.
            Subscribe now by clicking here.
          </action-card>
          <action-card v-if="showListingWarning" type="warning" large class="mb-4">
            You need to add both your skills and areas to be listed in the directory.
          </action-card>
          <area-editor :disabled="!emailVerified || !profileValid"></area-editor>
        </v-col>
      </v-row>
    </tab-content>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import AreaEditor from '@/components/AreaEditor.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileAreasTab',
  components: { TabTitle, TabContent, ActionCard, AreaEditor },
  computed: {
    showSubscribeWarning() {
      return (this.profileSkillCount > 0 || this.areaCount > 0)
        && !this.profileViewable
    },
    showListingWarning() {
      return (this.profileSkillCount == 0 || this.areaCount == 0)
        && this.profileViewable
    },
    ...mapGetters([
      'emailVerified',
      'profileValid',
      'profileSkillCount',
      'profileViewable',
      'areaCount'
    ])
  },
}
</script>