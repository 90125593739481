import Vue from 'vue'
import VueRouter from 'vue-router'
import AppShellView from '../views/AppShellView.vue'
import DashboardView from '../views/DashboardView.vue'
import ProfileEditorView from '../views/ProfileEditorView.vue'
import ProfileInfoTab from '../views/ProfileInfoTab.vue'
import ProfileResumeTab from '../views/ProfileResumeTab.vue'
import ProfilePhotosTab from '../views/ProfilePhotosTab.vue'
import ProfileSkillsTab from '../views/ProfileSkillsTab.vue'
import ProfileAreasTab from '../views/ProfileAreasTab.vue'
import OffersView from '../views/OffersView.vue'
import OfferView from '../views/OfferView.vue'
import OfferDetailsTab from '../views/OfferDetailsTab.vue'
import OfferSenderTab from '../views/OfferSenderTab.vue'
import OfferLocationTab from '../views/OfferLocationTab.vue'
import GigsView from '../views/GigsView.vue'
import GigsListTab from '../views/GigsListTab.vue'
import GigEditorView from '../views/GigEditorView.vue'
import GigEditorDetailsTab from '../views/GigEditorDetailsTab.vue'
import GigEditorSearchTab from '../views/GigEditorSearchTab.vue'
import GigEditorShortlistTab from '../views/GigEditorShortlistTab.vue'
import GigEditorOffersTab from '../views/GigEditorOffersTab.vue'
import SettingsView from '../views/SettingsView.vue'
import AppConfigTab from '../views/AppConfigTab.vue'
import AccountConfigTab from '../views/AccountConfigTab.vue'
import SubscriptionConfigTab from '../views/SubscriptionConfigTab.vue'
import EntranceView from '../views/EntranceView.vue'
import { fbauth } from '@/plugins/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/welcome',
    name: 'entrance',
    component: EntranceView,
    beforeEnter: (to, from, next) => {
      if (fbauth.currentUser) {
        next({ name: 'dashboard' })
      } else {
        next()
      }
    },
  },
  {
    path: '',
    name: 'appshell',
    component: AppShellView,
    beforeEnter: (to, from, next) => {
      if (fbauth.currentUser) {
        if (to.query.o) {
          next({ path: 'offers/' + to.query.o })
        } else if (to.query.r === 'subscription') {
          next({ name: 'setsubscriptionconfig' })
        } else {
          next()
        }
      } else {
        next({ name: 'entrance', query: to.query })
      }
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView,
      },
      {
        path: '/profile',
        name: 'profile',
        component: ProfileEditorView,
        children: [
          {
            path: 'info',
            name: 'profileinfo',
            component: ProfileInfoTab,
          },
          {
            path: 'biog',
            name: 'profilebiog',
            component: ProfileResumeTab,
          },
          {
            path: 'photos',
            name: 'profilephotos',
            component: ProfilePhotosTab,
          },
          {
            path: 'skills',
            name: 'profileskills',
            component: ProfileSkillsTab,
          },
          {
            path: 'areas',
            name: 'profileareas',
            component: ProfileAreasTab,
          },
        ],
      },
      {
        path: '/offers',
        name: 'offers',
        component: OffersView,
      },
      {
        path: '/offers/:id',
        name: 'offerview',
        component: OfferView,
        children: [
          {
            path: 'details',
            name: 'offerdetails',
            component: OfferDetailsTab,
          },
          {
            path: 'sender',
            name: 'offersender',
            component: OfferSenderTab,
          },
          {
            path: 'location',
            name: 'offerlocation',
            component: OfferLocationTab,
          },
        ]
      },
      {
        path: '/gigs',
        name: 'gigs',
        component: GigsView,
        children: [
          {
            path: 'active',
            name: 'gigsactive',
            component: GigsListTab,
          },
          {
            path: 'archive',
            name: 'gigsarchive',
            component: GigsListTab,
          },
        ],
      },
      {
        path: '/gigs/:id',
        name: 'gigeditor',
        component: GigEditorView,
        children: [
          {
            path: 'details',
            name: 'gigeditordetails',
            component: GigEditorDetailsTab,
          },
          {
            path: 'search',
            name: 'gigeditorsearch',
            component: GigEditorSearchTab,
          },
          {
            path: 'shortlist',
            name: 'gigeditorshortlist',
            component: GigEditorShortlistTab,
          },
          {
            path: 'offers',
            name: 'gigeditoroffers',
            component: GigEditorOffersTab,
          },
        ],
      },
      {
        path: '/settings',
        name: 'settings',
        component: SettingsView,
        children: [
          {
            path: '',
            component: AppConfigTab,
          },
          {
            path: 'config',
            name: 'setappconfig',
            component: AppConfigTab,
          },
          {
            path: 'account',
            name: 'setaccountconfig',
            component: AccountConfigTab,
          },
          {
            path: 'subscription',
            name: 'setsubscriptionconfig',
            component: SubscriptionConfigTab,
          },
        ],
      },
      {
        path: '*',
        redirect: '/dashboard'
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
