<template>
  <v-card @click="buttonClick(2)"
    tile color="white black--text"
  >
    <div>
      <v-card-title>
        {{ offer.gig.title }}
      </v-card-title>
      <v-card-subtitle>
        From: {{ fromName }}<br>
        Sent: {{ formatTime(offer.sent[offer.sent.length-1]) }}
      </v-card-subtitle>
      <v-card-text>
        {{ offer.gig.address }}<br>
        {{ formatDate(offer.gig.date) }}
      </v-card-text>
    </div>
    <div class="button-row">
      <v-icon color="secondary" class="ml-3">{{ icon1 }}</v-icon>
      <v-icon color="secondary" class="ml-3">{{ icon0 }}</v-icon>
    </div>
  </v-card>
</template>

<script>
import { eventBus } from '@/main'

const VIEW = 2

export default {
  props: {
    offer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      icon0: null
    }
  },
  computed: {
    icon1() {
      return this.offer.gig.archived ? 'mdi-archive' : ''
    },
    fromName() {
      if (this.offer.from) {
        return this.offer.from.name
      } else {
        return 'Subscribe to see the sender'
      }
    }
  },
  methods: {
    formatDate(date) {
      return Intl.DateTimeFormat('en-gb', {
        weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
      }).format(new Date(date))
    },
    formatTime(time) {
      return Intl.DateTimeFormat('en-gb', {
        weekday:'long', day: 'numeric', month: 'long', year: 'numeric',
        hour: 'numeric', minute: 'numeric'
      }).format(new Date(time))
    },
    buttonClick(button) {
      if (button === VIEW) {
        eventBus.$emit('cardButtonClick', this.offer.id, 'viewOffer')
      }
    },
  },
  created() {
    if (this.offer.seen) {
      this.icon0 = 'mdi-email-open'
    } else if (this.offer.sent) {
      this.icon0 = 'mdi-email'
    } else {
      this.icon0 = 'mdi-email-outline'
    }
  },
}
</script>

<style scoped>
  .button-row {
    bottom: 0;
    right: 0;
    position: absolute;
    margin: 0 12px 12px 0;
  }
</style>
