<template>
  <div>
    <paypal-button v-if="showPaypal"></paypal-button>

    <div v-if="filteredSubscriptions.length > 0">
      <p class="text-h6 mt-4">Previous subscriptions</p>
      <p class="text-caption">You can manage your active subscription at your PayPal dashboard.</p>
      <v-simple-table v-for="(s, n) in filteredSubscriptions" :key="n" dense class="ma-4">
        <tbody>
          <tr><th>Plan:</th><td>{{ s.name }}</td></tr>
          <tr><th>Status:</th><td class="text-uppercase">{{ s.status }}</td></tr>
          <tr><th>Expires:</th><td>{{ s.expires }}</td></tr>
          <tr><th>ID:</th><td>{{ s.id }}</td></tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import PaypalButton from '@/components/PaypalButton.vue'
import { fbfs, fbfuncs } from '@/plugins/firebase'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

export default {
  name: 'SubscriptionView',
  components: { PaypalButton },
  data() {
    return {
      subscriptions: [],
      active: false,
      dbUnsubscribeChanges: null
    }
  },
  computed: {
    filteredSubscriptions() {
      return this.subscriptions.filter(s => {
        return s.id.startsWith('I-')
      })
    },
    showPaypal() {
      return !this.active
    }
  },
  methods: {
  },
  created() {
    const q = query(collection(fbfs, 'subscriptions'), where('user', '==', this.$store.state.user.uid))
    // eslint-disable-next-line no-unused-vars
    this.dbUnsubscribeChanges = onSnapshot(q, async (snapshot) => {
      try {
        eventBus.$emit('appAlterAppBar', { loading: true })

        const getMySubscriptions = httpsCallable(fbfuncs, 'getMySubscriptions')
        const result = await getMySubscriptions()

        eventBus.$emit('appAlterAppBar', { loading: false })
        this.active = result.data.active ? true : false
        this.subscriptions = result.data.subscriptions ? result.data.subscriptions : []
      } catch (e) {
        eventBus.$emit('appAlterAppBar', { loading: false })
        eventBus.$emit('appWarningSet', { message: 'Subscription: ' + e })
      }
    })
  },
  beforeDestroy() {
    if (this.dbUnsubscribeChanges) {
      this.dbUnsubscribeChanges()
    }
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}
</style>