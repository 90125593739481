<template>
  <div>
    <tab-title>{{ titleText }}</tab-title>
    <tab-content>
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <action-card>{{ helpText }}</action-card>
          <v-card v-for="(gig, i) in listedGigs()" :key="i"
            @click="cardClicked(gig.id)"
            tile color="white black--text" class="my-4"
          >
            <v-card-title>{{gig.title}}</v-card-title>
            <v-card-text>
                {{ gig.location.address }}<br>
                {{ formatDate(gig.date) }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </tab-content>
  </div>
</template>

<!--  TODO: Gig list doesn't update after page reload -->

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'GigsListTab',
  components: { TabTitle, TabContent, ActionCard },
  computed: {
    listedGigs() {
      if (this.$route.name === 'gigsactive') {
        return this.activeGigs
      } else if (this.$route.name === 'gigsarchive') {
        return this.archivedGigs
      }
      return null
    },
    titleText() {
      if (this.$route.name === 'gigsactive') {
        return 'Active'
      } else if (this.$route.name === 'gigsarchive') {
        return 'Archived'
      }
      return ''
    },
    helpText() {
      if (this.$route.name === 'gigsactive') {
        return 'The gigs that you are currently working on.'
      } else if (this.$route.name === 'gigsarchive') {
        return 'Inactive gigs that you have archived. You can view the details but you cannot modify them.'
      }
      return ''
    },
    ...mapGetters([
      'activeGigs',
      'archivedGigs'
    ])
  },
  methods: {
    cardClicked(id) {
      this.$router.push({ name: 'gigeditordetails', params: { id: id } })
    },
    formatDate(date) {
      try {
        return new Intl.DateTimeFormat('en-gb', {
          weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
        }).format(new Date(date))
      } catch (e) {
        if (e instanceof RangeError) {
          return 'Invalid date format'
        } else {
          throw e
        }
      }
    }
  }
}
</script>